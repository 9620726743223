import '@assets/global.scss'
import '@assets/chrome-bug.scss'
import 'keen-slider/keen-slider.min.css'

import { FC, useEffect, useState } from 'react'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'

import { DEBUG, GA_TRACKING_ID } from 'siteConfig'
import { Head, AppInner } from '@components/common'
import { ManagedUIContext } from '@components/ui/context'
import {
  GlobalContentContext,
  GlobalContentContextStateType,
  GlobalContentContextType,
} from '@context/GlobalContentContext'
import { useTransitionFix } from '@hooks/useTransitionFix'
import { useEffectOnce } from 'usehooks-ts'
import { CmsHtml } from '@components/html/poly'

const Noop: FC = ({ children }) => <>{children}</>

export default function MyApp(props: AppProps) {
  const Layout = (props.Component as any).Layout || Noop
  const router = useRouter()

  useEffect(() => {
    document.body.classList?.remove('loading')
  }, [])

  const [globalContentContextData, setGlobalContentContextData] =
    useState<GlobalContentContextType>({
      social_media: undefined,
      analytics: undefined,
      site_meta_data: {
        meta_title: '',
        meta_description: '',
        meta_image: '',
      },
      footer: {
        detail: '',
        copywrite: '',
      },
      nav_image: {
        image_height: 0,
        image_width: 0,
        image: '',
      },
      sidebar: {
        find_fitter_sidebar_title: '',
        find_fitter_sidebar_detail: '',
        find_fitter_sidebar_suggest_detail: '',
        fitters: [],
      },
    })

  const globalContentContext: GlobalContentContextStateType = {
    data: globalContentContextData,
    setData: setGlobalContentContextData,
  }

  useEffectOnce(() => {
    document.fonts.ready.then(() => {
      document.body.classList.add('is-fonts-loaded')
    })

    // https://github.com/vercel/next.js/issues/17464#issuecomment-914561683
    if (router.pathname === '/404') {
      router.push(router.pathname)
    } else {
      router.push(router.asPath, undefined, { scroll: false })
    }
  })

  useTransitionFix((props.pageProps as any).error !== undefined)

  return (
    <>
      <Head />

      {!DEBUG && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                  dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}');
          `,
            }}
          />
          <Script
            strategy="afterInteractive"
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=3934a98d-be86-43ba-b0d8-fe0d8da385f5"
          />
          <Script
            src="https://static.leaddyno.com/js"
            onLoad={() => {
              // @ts-ignore
              LeadDyno.key = '57461e50515e323d4ea65fb1e1b2c3f27ba96794'
              // @ts-ignore
              LeadDyno.recordVisit()
              // @ts-ignore
              LeadDyno.autoWatch()
            }}
          />
        </>
      )}

      <GlobalContentContext.Provider value={globalContentContext}>
        <ManagedUIContext>
          <Layout pageProps={props.pageProps}>
            <AppInner {...props} />
          </Layout>
        </ManagedUIContext>
      </GlobalContentContext.Provider>
    </>
  )
}
